import {BigEmailIcon} from "../assets/BigEmailIcon";
import {Logo} from "../assets/Logo";
import {AppStoreLogo} from "../assets/AppStoreLogo";
import {GooglePlayLogo} from "../assets/GooglePlayLogo";
import {BigInstaIcon} from "../assets/BigInstaIcon";
import {SmallEmailIcon} from "../assets/SmallEmailIcon";
import {SmallInstaIcon} from "../assets/SmallInstaIcon";
import coin1 from "../assets/coin1.webp";
import coin2 from "../assets/coin2.webp";
import coin3 from "../assets/coin3.webp";
import coin4 from "../assets/coin4.webp";
import astana from "../assets/mocks/astana_icon.png";
import pci from "../assets/mocks/pci.png";
import {useNavigate} from "react-router";
import {Button} from "@nextui-org/react";
import {SorpaWhiteIcon} from "../assets/SorpaWhiteIcon";

const Footer = () => {
    const history = useNavigate();

    const lang = localStorage.getItem("lang");

    const l = {
        "ru": {
            "text1": {
                "label": "Присоединяйтесь сейчас",
                "color": "black"
            },
            "text2": {
                "label": "Помните, что количество мест в каждой категории услуг для каждого города ограничено. Успейте получить свой рецепт успеха.",
                "color": "black"
            },
            "text3": {
                "label": "Оставить заявку",
                "color": "white"
            },
            "text4": {
                "label": "Наша миссия",
                "color": "black"
            },
            "text5": {
                "label": "Мы стремимся объединить предпринимателей и\n" +
                    "клиентов в экосистеме взаимной выгоды, где\n" +
                    "каждая транзакция приносит радость и пользу",
                "color": "black"
            },
            "text6": {
                "label": "Sorpa - всегда с добавкой!",
                "color": "black"
            },
            "text7": {
                "label": "Правила платежной организаций",
                "color": "black"
            },
            "text8": {
                "label": "Правила пользования",
                "color": "black"
            },
            "text9": {
                "label": "Политика конфиденциальности",
                "color": "black"
            },
            "text10": {
                "label": "(c) Разработано Частная Компания \"Clover Ltd\"." +
                    " Все права защищены ТОО “OMASA group” 2024.\n",
                "color": "black"
            },
        },
        "kk": {
            "text1": {
                "label": "Қазір қосылыңыз",
                "color": "black"
            },
            "text2": {
                "label": "Әр қаладағы қызметтер санаты бойынша орын саны шектеулі екенін ұмытпаңыз. Өз табыс рецептіңізді алуға үлгеріңіз.",
                "color": "black"
            },
            "text3": {
                "label": "Өтінім беру",
                "color": "white"
            },
            "text4": {
                "label": "Біздің миссиямыз",
                "color": "black"
            },
            "text5": {
                "label": "Біз әрбір транзакция қуаныш пен пайда әкелетін өзара тиімді экожүйеде кәсіпкерлер мен клиенттерді біріктіруге ұмтыламыз",
                "color": "black"
            },
            "text6": {
                "label": "Sorpa - әрқашан қосымшамен!",
                "color": "black"
            },
            "text7": {
                "label": "Төлем ұйымының ережелері",
                "color": "black"
            },
            "text8": {
                "label": "Пайдалану ережелері",
                "color": "black"
            },
            "text9": {
                "label": "Құпиялылық саясаты",
                "color": "black"
            },
            "text10": {
                "label": "(c) Частная Компания Clover Ltd. әзірленген. “OMASA group” ЖШС 2024. Барлық құқықтар қорғалған.",
                "color": "black"
            },
        }
    }

    return(
        <footer id={'contacts'} style={{background: "#131313", paddingBottom:60, color:"white"}}>
            <div className={"container overflow-hidden"}>
                <div className={"bg-[#BDFF00]  mt-[80px] text-black py-[40px] md:py-[80px] flex flex-col justify-center items-center p-[24px] gap-[24px] rounded-[24px] w-full relative overflow-hidden"}>
                    <h3 className={"text-[32px] font-[600] relative z-30"}>{l[lang]?.text1?.label}</h3>
                    <p className={"max-w-[600px] text-center relative z-30"}>{l[lang]?.text2?.label}</p>
                    <a href={"/signup"}><Button className={"bg-black text-white w-full md:w-[270px] rounded-full relative z-30"}>{l[lang]?.text3?.label}</Button></a>
                    <img className={"absolute z-20 w-[80px] left-[160px] bottom-[20px]"} src={coin1}/>
                    <img className={"absolute z-20 w-[80px] left-[513px] bottom-[20px]"} src={coin2}/>
                    <img className={"absolute z-20 w-[120px] left-[806px] bottom-[20px]"} src={coin3}/>
                    <img className={"absolute z-20 w-[120px] right-[20px] top-0"} src={coin4}/>
                </div>
            </div>
            <div className={'container'}>
                <div className={"footer-main-wrapper"} style={{display:"flex", gap:24, flexDirection:"column"}}>
                    <h2 style={{maxWidth:400, textAlign:"center", fontSize:32, fontWeight:600}}>{l[lang]?.text4?.label}</h2>
                    <p style={{maxWidth:400, textAlign:"center"}}>{l[lang]?.text5?.label}</p>
                </div>
                <div className={"footer-info-block"} style={{width:"100%", background:'#000000', borderRadius:30, padding: 40}}>
                    <a href={'mailto:info@sorpa.com'} style={{display:"flex", alignItems:"center", gap:16, width:"100%"}}><BigEmailIcon/>info@sorpa.com</a>
                    <div className={'header-wrapper'} style={{width:6, background:"white"}}/>
                    <a href={'https://www.instagram.com/sorpa.app/'} target={"_blank"} className={"insta-wrapper"} style={{display:"flex", alignItems:"center", gap:16, width:"100%"}}><BigInstaIcon/>@sorpa.app</a>
                </div>
                <div className={"footer-end"}>
                    <div style={{display:"flex", flexDirection:"column", gap:30, width:"100%"}}>
                            <SorpaWhiteIcon/>
                            <p>{l[lang]?.text6?.label}</p>
                        <div style={{display:"flex", gap:24}}>
                            <AppStoreLogo/>
                            <a href={'https://play.google.com/store/apps/details?id=com.bekinston.Sorpa&pli=1'}><GooglePlayLogo/></a>
                        </div>
                    </div>
                    <div style={{display:"flex", flexDirection:"column", gap:30, width:"100%"}}>
                        <p className={"footer-links"} style={{fontSize:12}} onClick={() => {
                            setTimeout(()=>{window.scrollTo(0,0)}, [100])
                        }}>
                            {/*<a href={'/Правила_Платежной_Организации.pdf'}>{l[lang]?.text7?.label}</a> |*/}
                            <a target={"_blank"} href={'/rules.pdf'}>{l[lang]?.text8?.label}</a> <span className={"hidden md:inline"}>|</span> <a target={"_blank"} href={'/policy.pdf'} className={"w-full md:w-auto"}><br className={"flex md:hidden"}/>{l[lang]?.text9?.label}</a></p>
                        <div className={"footer-links"} style={{display:"flex", gap:30, alignItems:"center"}}>
                            <a href={'mailto:info@sorpa.com'}><SmallEmailIcon/></a>
                            <a target={"_blank"} href={'https://www.instagram.com/sorpa.app/'}><SmallInstaIcon/></a>
                            {/*<a target={"_blank"} className={"bg-white p-[10px] rounded-xl"} href={"https://astanahub.com/account/company/7097/"}><img alt={"astana_hub_icon"} src={astana} className={"object-contain h-[60px]"}/></a>*/}
                            {/*<a target={"_blank"} className={"bg-white p-[10px] rounded-xl"} href={"/5463326232079887323.jpg"}><img alt={"astana_hub_icon"} src={pci} className={"object-contain h-[60px]"}/></a>*/}
                        </div>
                        <div style={{display:"flex", gap:24}}>
                            <p className={"footer-links"} style={{fontSize:12}}>{l[lang]?.text10?.label}</p>
                        </div>
                    </div>
                </div>
            </div>
        </footer>
    )
}

export default Footer;
